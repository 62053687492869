<template>
  <div class="box">
    <div class="box_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="box_right" :class="{ box_right1: b == true }">
      <div id="xqq">
        <div class="xqq_fen">经费支出总览表（单位：万元）</div>
        <div class="xqq_checkbox">
          <el-checkbox v-model="fn1">显示预算</el-checkbox>
          <el-checkbox v-model="fn2">显示项目</el-checkbox>
          <el-checkbox v-model="fn3">显示课题</el-checkbox>
          <el-checkbox v-model="fn4">显示子课题</el-checkbox>
        </div>
        <div class="ple">
          <!--             <div class="bar" ref="barparent"></div>-->
          <div class="box_body" v-for="(row, index) in tableData" :key="index" :style="{ width: widthzhi + 'px' }"
            id="table">
            <div class="box_div1" v-if="index == 0">
              <div class="box_div1_one">
                <div class="box_divone1"><span>项目名称</span></div>
                <div class="box_divone2">
                  <span></span>
                </div>
                <div class="box_div_two" v-for="(item, index) in row.TopicFinancAccountStatistics" :key="index">
                  <span><a :class="classi(index)"><i class="ci" v-show="index == 3 || index == 4 || index == 5 || index == 6
                      ? true
                      : false
                    "></i>{{ item.AccountTitle }}</a></span>
                </div>
              </div>
              <div class="box_div2" v-show="fn1">
                <div class="box_div2_1"><span>预算</span></div>
                <div class="box_div2_2">
                  <span>预算合计</span>
                  <span>中央财政资金</span>
                  <span>其它来源资金</span>
                </div>
                <div class="box_div2_3" v-for="(item, index) in row.TopicFinancAccountStatistics" :key="index">
                  <span :class="{ po: item.BudgetNum < 0 ? true : false }"><el-input-number v-model="item.BudgetNum"
                      @change="calculation" disabled :precision="2" :controls="false"></el-input-number></span>
                  <span :class="{ po: item.CentralBudget < 0 ? true : false }"><el-input-number
                      v-model="item.CentralBudget" @change="calculation" :disabled="item.SumStart == 4 ? true : false"
                      :precision="2" :controls="false"></el-input-number></span>
                  <span :class="{ po: item.OtherBudget < 0 ? true : false }"><el-input-number v-model="item.OtherBudget"
                      @change="calculation" :disabled="item.SumStart == 4 ? true : false" :precision="2"
                      :controls="false"></el-input-number></span>
                </div>
              </div>
              <div class="box_div3" v-show="row.Type == 1
                  ? fn2
                  : row.Type == 2
                    ? fn3
                    : row.Tpye == 3
                      ? fn4
                      : fn4
                ">
                <div class="box_div3_1" @click="clickfn(row.TopicId, row.SerialNumber)">
                  <el-tooltip class="item" effect="light" :content="row.SerialNumber" placement="top">
                    <span style="cursor: pointer">{{
                      (row.SerialNumber + "合计") | ellipsis
                    }}</span>
                  </el-tooltip>
                </div>
                <div class="box_div3_2">
                  <span>支出</span>
                  <span>结余</span>
                </div>
                <div class="box_div3_3" v-for="(item, index) in row.TopicFinancAccountStatistics" :key="index"
                  @click="rePeoplemessageCard(row, item)">
                  <span style="cursor: pointer" :class="{ inp: item.PayoutMoneyNum < 0 ? true : false }">{{
                    item.PayoutMoneyNum }}</span>
                  <span style="cursor: pointer" :class="{ inp: item.SurplusNum < 0 ? true : false }">{{ item.SurplusNum
                  }}</span>
                </div>
              </div>
            </div>
            <div v-else class="div4" v-show="row.Type == 1
                ? fn2
                : row.Type == 2
                  ? fn3
                  : row.Tpye == 3
                    ? fn4
                    : fn4
              " :class="fn(row.Type)">
              <div class="div4_1" @click="clickfn(row.TopicId, row.SerialNumber)">
                <el-tooltip class="item" effect="light" :content="row.SerialNumber" placement="top">
                  <span style="cursor: pointer">{{
                    (row.SerialNumber + "（合计）") | ellipsis
                  }}</span>
                </el-tooltip>
              </div>
              <div class="div4_2">
                <span>支出</span>
                <span>结余</span>
              </div>
              <div class="div4_3" v-for="(item, index) in row.TopicFinancAccountStatistics" :key="index">
                <span @click="rePeoplemessageCard(row, item)" :class="{ inp: item.PayoutMoneyNum < 0 ? true : false }"
                  style="cursor: pointer">{{ item.PayoutMoneyNum }}</span>
                <span :class="{ inp: item.SurplusNum < 0 ? true : false }" style="cursor: pointer">{{ item.SurplusNum
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" @click="eXCEL">导出EXCEL</el-button>
        <el-button type="primary" @click="submintBudget">保存预算</el-button>
      </div>
    </div>
    <guidance-chart :tips="tips" @tips-child="tipsChild"></guidance-chart>
    <div class="financialmanagementdialog">
      <!----弹框------->
      <el-dialog :visible.sync="financialmanagementdialog" top="0vh" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">{{ name }}经费支出总览表 （单位：万元）</span>
        </div>
        <div class="body">
          <el-table :data="tableFundData" style="width: 100%" height="700" border>
            <el-table-column label="项目名称" align="center">
              <el-table-column width="300">
                <template slot-scope="scope">
                  <span :class="classi(scope.$index)"><i class="ci" v-show="scope.$index == 3 ||
                      scope.$index == 4 ||
                      scope.$index == 5 ||
                      scope.$index == 6
                      ? true
                      : false
                    "></i>{{ scope.row.AccountTitle }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="预算" align="center">
              <el-table-column label="预算合计" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{ po: scope.row.BudgetNum < 0 ? true : false }">
                    <el-input-number v-model="scope.row.BudgetNum" disabled :precision="2"
                      :controls="false"></el-input-number>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="中央财政资金" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{ po: scope.row.CentralBudget < 0 ? true : false }">
                    <el-input-number v-model="scope.row.CentralBudget" @input="changeBudgetNum"
                      :disabled="scope.row.SumStart == 4 ? true : false" :precision="2"
                      :controls="false"></el-input-number>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="其它来源资金" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{ po: scope.row.OtherBudget < 0 ? true : false }">
                    <el-input-number v-model="scope.row.OtherBudget" @input="changeBudgetNum"
                      :disabled="scope.row.SumStart == 4 ? true : false" :precision="2"
                      :controls="false"></el-input-number>
                  </span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="实际" align="center">
              <el-table-column label="实际合计" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{
                    inp: scope.row.PayoutMoneyNum < 0 ? true : false,
                  }" @click="clickCentralPayoutMoney1(scope.$index, scope.row)">{{ scope.row.PayoutMoneyNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="中央财政资金" width="150" align="center">
                <template slot-scope="scope">
                  <span @click="clickCentralPayoutMoney2(scope.$index, scope.row)" :class="{
                    inp: scope.row.CentralPayoutMoney < 0 ? true : false,
                  }">{{ scope.row.CentralPayoutMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column label="其它来源资金" width="150" align="center">
                <template slot-scope="scope">
                  <span @click="clickCentralPayoutMoney3(scope.$index, scope.row)" :class="{
                    inp: scope.row.OtherPayoutMoney < 0 ? true : false,
                  }">{{ scope.row.OtherPayoutMoney }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="结余" align="center">
              <el-table-column label="结余合计" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{ inp: scope.row.SurplusNum < 0 ? true : false }">{{ scope.row.SurplusNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="中央财政资金" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{
                    inp: scope.row.CentralSurplus < 0 ? true : false,
                  }" @click="clickCentralSurplus(scope.$index, scope.row)">{{ scope.row.CentralSurplus }}</span>
                </template>
              </el-table-column>
              <el-table-column label="其它来源资金" width="150" align="center">
                <template slot-scope="scope">
                  <span :class="{ inp: scope.row.OtherSurplus < 0 ? true : false }"
                    @click="clickOtherSurplus(scope.$index, scope.row)">{{ scope.row.OtherSurplus }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="btn">
          <button @click="financialmanagementdialog = false">关闭</button>
          <button @click="Savebudget">保存预算</button>
        </div>
      </el-dialog>
    </div>
    <!------弹框----->
    <div class="Voucherlistdialog">
      <el-dialog :visible.sync="Voucherlistdialog" fullscreen>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">凭证列表</span>
        </div>
        <div class="body">
          <FinanceList :parame="voucherListData" :key="Math.random()"></FinanceList>
        </div>
      </el-dialog>
    </div>
    <div class="Voucherlistdialog1">
      <el-dialog :visible.sync="Voucherlistdialog1" top="15vh">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <span>!</span><span>是否保存当前填写的数据？</span>
        </div>
        <div class="btn">
          <button @click="Cancelleaving">取消</button>
          <button @click="okdetermine">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="Voucherlistdialog1">
      <el-dialog :visible.sync="Voucherlistdialog2" top="15vh">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <span>!</span><span>是否保存当前填写的数据？</span>
        </div>
        <div class="btn">
          <button @click="unPath">取消</button>
          <button @click="okpath">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import FinanceList from "@/components/FinanceLIst";
import api from "../../../api/url";
import treedata from "../../../components/treedata.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tips: {
        name: "chartAccounts",
        url: require("../../../assets/image/tips/financez.png"),
      },
      saveTableData: [], // 取消的时候大表的值
      chartTo: "",
      Voucherlistdialog2: false,
      num1: 1,
      num2: 0,
      num3: 0,
      num4: 0,
      numx: 0,
      numk: 0,
      numz: 0,
      widthzhi: "",
      fn1: true,
      fn2: true,
      fn3: true,
      fn4: true,
      tableData: [],
      tableFundData: [],
      financialmanagementdialog: false,
      Voucherlistdialog: false,
      Voucherlistdialog1: false,
      // Voucherlistdialog2:false,
      voucherListId: "1", // 组件的Id
      voucherListData: {}, // 需要传递的参数凭证对象
      pid: "",
      name: "",
      flag: false,
      datanum: "",
      path: "",
      show: false,
      table: [{ AccountTitle: 1, BudgetNum: 2, CentralBudget: 3, OtherBudget: 4, PayoutMoneyNum: 6, SurplusNum: 7, PayoutMoneyNum1: 8, SurplusNum1: 9 }]
    };
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    console.log(from);
    this.chartTo = to;
    if (this.flag) {
      this.Voucherlistdialog2 = true;
      next(false);
    } else {
      next();
    }
  },
  filters: {
    /**
     * 文件名超出24个字符后显示省略号
     */
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  computed: mapState(["projectId", "b"]),
  components: {
    treedata,
    FinanceList,
  },
  watch: {
    fn1() {
      if (this.fn1) {
        this.num1 = 1;
        this.php();
      } else {
        this.num1 = 0;
        this.php();
      }
    },
    fn2() {
      if (this.fn2) {
        this.num2 = this.numx;
        this.php();
      } else {
        this.num2 = 0;
        this.php();
      }
    },
    fn3() {
      if (this.fn3) {
        this.num3 = this.numk;
        this.php();
      } else {
        this.num3 = 0;
        this.php();
      }
    },
    fn4() {
      if (this.fn4) {
        this.num4 = this.numz;
        this.php();
      } else {
        this.num4 = 0;
        this.php();
      }
    },
  },
  methods: {
    eXCEL() {
      // 懒加载，@/common/vendor/Export2Excel 此路径为本地路径
      import('../../../utils/Export2Excel.js').then((excel) => {
        let multiHeader = [
          ['项目名称', '预算', '', '']
        ];
        let multiHeader2 = [
          [
            '', '预算合计', '中央财政资金', '其它来源资金',
          ],
        ];
        let filterVal = ["AccountTitle", "BudgetNum", "CentralBudget", "OtherBudget"];// 表头所对应的字段，这里未填写，请自行填写对应的字段，按实际需求请自行处理该步
        this.tableData.forEach((item, index) => {
          multiHeader[0].push(`${item.SerialNumber}合计`, "")
          multiHeader2[0].push("支出", "结余")
          if (index > 0) {
            filterVal.push(`PayoutMoneyNum${index}`, `SurplusNum${index}`)
          } else {
            filterVal.push(`PayoutMoneyNum`, `SurplusNum`)
          }
        })
        this.tableData.forEach((row, index, arr) => {
          if (index > 0) {
            arr[0].TopicFinancAccountStatistics.forEach((f, i) => {
              this.$set(f, `PayoutMoneyNum${index}`, row.TopicFinancAccountStatistics[i].PayoutMoneyNum)
              this.$set(f, `SurplusNum${index}`, row.TopicFinancAccountStatistics[i].SurplusNum)
            })
          }

        })
        const data = this.tableData[0].TopicFinancAccountStatistics.map(v => filterVal.map(j => v[j]));
        // 进行所有表头的单元格合并
        const merges = [
          // 'A1:A2','B1:C1','E1:F1','G1:H1'
        ];

        excel.export_json_to_excel({
          multiHeader, // 这里是第一行的表头
          multiHeader2, // 这里是第二行的表头
          // header: tHeader, // 这里是第三行的表头
          data,
          filename: '科目表',
          merges,
        });
      });
    },
    tipsChild(data) {
      localStorage.setItem(this.tips.name, data);
    },
    submintBudget2() {
      //大表保存预算
      var Accounts = [];
      this.tableData[0].TopicFinancAccountStatistics.forEach((item) => {
        Accounts.push({
          Id: item.AccountId,
          TopicId: this.projectId,
          BaseAccountId: item.Id,
          CentralBudget: item.CentralBudget * 10000,
          OtherBudget: item.OtherBudget * 10000,
        });
      });
      let params = {
        Accounts: Accounts,
      };
      api.UpdatebudgetData(params).then((res) => {
        if (res.data.ExtraData == true) {
          this.flag = false;
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push(this.chartTo);
        } else {
          alert(res.data.Message);
        }
      });
    },
    okpath() {
      this.Voucherlistdialog2 = false;
      this.submintBudget2();
    },
    unPath() {
      this.flag = false;
      this.Voucherlistdialog2 = false;
      this.$router.push(this.chartTo);
    },
    php() {
      this.widthzhi =
        (this.num2 + this.num3 + this.num4) * 150 + 450 * this.num1 + 450;
    },
    clickCentralPayoutMoney1(i, row) {
      console.log(row);
      let parame = {
        field: "PayoutMoneyNum",
        topicId: this.pid,
        Id: row.Id,
      };
      let routeData = this.$router.resolve({
        path: "/financeList",
        query: {
          parame: JSON.stringify(parame),
        },
      });
      window.open(routeData.href, "_blank");
    },
    clickCentralPayoutMoney2(i, row) {
      let parame = {
        field: "CentralPayoutMoney",
        topicId: this.pid,
        Id: row.Id,
      };
      let routeData = this.$router.resolve({
        path: "/financeList",
        query: {
          parame: JSON.stringify(parame),
        },
      });
      window.open(routeData.href, "_blank");
    },
    clickCentralPayoutMoney3(i, row) {
      let parame = {
        field: "OtherPayoutMoney",
        topicId: this.pid,
        Id: row.Id,
      };
      let routeData = this.$router.resolve({
        path: "/financeList",
        query: {
          parame: JSON.stringify(parame),
        },
      });
      window.open(routeData.href, "_blank");
    },
    classi(index) {
      if (index == 0) {
        return "span10";
      }
      if (index == 1) {
        return "span11";
      }
      if (index == 2) {
        return "span12";
      }
      if (index == 3) {
        return "span13";
      }
      if (index == 4) {
        return "span14";
      }
      if (index == 5) {
        return "span15";
      }
      if (index == 6) {
        return "span16";
      }
      if (index == 7) {
        return "span17";
      }
      if (index == 8) {
        return "span18";
      }
      if (index == 9) {
        return "span19";
      }
      if (index == 10) {
        return "span20";
      }
      if (index == 11) {
        return "span21";
      }
      if (index == 12) {
        return "span22";
      }
      if (index == 13) {
        return "span23";
      }
      if (index == 14) {
        return "span24";
      }
      if (index == 15) {
        return "span25";
      }
    },
    fn(type) {
      if (type == 2) {
        return "fnfn";
      }
      if (type == 3) {
        return "nfnf";
      }
    },
    submintBudget() {
      //大表保存预算
      var Accounts = [];
      this.tableData[0].TopicFinancAccountStatistics.forEach((item) => {
        Accounts.push({
          Id: item.AccountId,
          TopicId: this.projectId,
          BaseAccountId: item.Id,
          CentralBudget: item.CentralBudget * 10000,
          OtherBudget: item.OtherBudget * 10000,
        });
      });
      let params = {
        Accounts: Accounts,
      };
      api.UpdatebudgetData(params).then((res) => {
        if (res.data.ExtraData == true) {
          this.flag = false;
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.getTreeData();
        } else {
          alert(res.data.Message);
        }
      });
    },
    branch() {
      //大表的计算
      this.flag = true;
      this.tableData[0].TopicFinancAccountStatistics =
        this.tableData[0].TopicFinancAccountStatistics.map(
          (item, index, arr) => {
            var obj = {
              BudgetNum: 0,
              CentralBudget: 0,
              OtherBudget: 0,
            };
            if (index == 2) {
              for (var i2 = item.SumStart - 1; i2 < item.SumEnd; i2++) {
                obj.BudgetNum += arr[i2].BudgetNum * 1;
                obj.CentralBudget += arr[i2].CentralBudget * 1;
                obj.OtherBudget += arr[i2].OtherBudget * 1;
              }
              item.BudgetNum = obj.BudgetNum;
              item.CentralBudget = obj.CentralBudget;
              item.OtherBudget = obj.OtherBudget;
            }
            if (index == 1) {
              for (var i1 = item.SumStart - 1; i1 < item.SumEnd; i1++) {
                obj.BudgetNum += arr[i1].BudgetNum * 1;
                obj.CentralBudget += arr[i1].CentralBudget * 1;
                obj.OtherBudget += arr[i1].OtherBudget * 1;
              }
              item.BudgetNum = obj.BudgetNum;
              item.CentralBudget = obj.CentralBudget;
              item.OtherBudget = obj.OtherBudget;
            }
            if (index == 0) {
              for (var i = item.SumStart - 1; i < item.SumEnd; i++) {
                obj.BudgetNum += arr[i].BudgetNum * 1;
                obj.CentralBudget += arr[i].CentralBudget * 1;
                obj.OtherBudget += arr[i].OtherBudget * 1;
              }
              item.BudgetNum = obj.BudgetNum;
              item.CentralBudget = obj.CentralBudget;
              item.OtherBudget = obj.OtherBudget;
            }
            item.BudgetNum = item.CentralBudget * 1 + item.OtherBudget * 1;
            return item;
          }
        );
    },
    calculation() {
      this.branch();
    },
    clickfn(TopicId, SerialNumber) {
      //打开小表弹框
      console.log(SerialNumber);
      this.name = SerialNumber;
      this.pid = TopicId;
      this.financialmanagementdialog = true;
      api.DisplayReportData(TopicId).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.tableFundData = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
      });
    },
    getTreeData() {
      //大表
      let parame =
        this.projectId == ""
          ? sessionStorage.getItem("projectId")
          : this.projectId;
      console.log(parame, "项目");
      this.num2 = 0;
      this.num3 = 0;
      this.num4 = 0;
      this.num1 = 1;
      api.tableTitleData(parame).then((res) => {
        console.log(res, "大表");
        this.tableData = res.data.Entity;
        this.tableData.forEach((item) => {
          if (item.Type == 1) {
            this.num2++;
          }
          if (item.Type == 2) {
            this.num3++;
          }
          if (item.Type == 3) {
            this.num4++;
          }
        });
        this.numx = this.num2;
        this.numk = this.num3;
        this.numz = this.num4;
        this.widthzhi =
          (this.num2 + this.num3 + this.num4) * 150 + 450 * this.num1 + 450;
        console.log(this.widthzhi, "宽度");
      });
    },
    okdetermine() {
      this.submintBudget();
      this.Voucherlistdialog1 = false;
      this.flag = false;
      this.$store.commit("SaveId", this.datanum);
      this.getTreeData();
    },
    Cancelleaving() {
      this.flag = false;
      this.Voucherlistdialog1 = false;
      this.$store.commit("SaveId", this.datanum);
      this.getTreeData();
    },
    projectIdClick(data) {
      //树型结构传递过来的课题id
      this.datanum = data;
      if (this.flag) {
        this.Voucherlistdialog1 = true;
      } else {
        this.$store.commit("SaveId", data);
        this.getTreeData();
      }
    },
    refreshView() {
      // 刷新页面
      const { fullPath } = this.$route;
      this.$nextTick(() => {
        this.$router.replace({
          path: "/redirect" + fullPath,
        });
      });
    },
    Header(item) {
      //弹出显示报表数据
      this.pid = item.TopicId;
      this.DisplayReportlist(item.TopicId);
      this.financialmanagementdialog = true;
    },
    rePeoplemessageCard(row, item) {
      //打开凭证列表
      JSON.parse(JSON.stringify(row));
      let parame = {
        field: "PayoutMoneyNum",
        topicId: row.TopicId,
        Id: item.Id,
      };
      this.voucherListData = parame;
      let routeData = this.$router.resolve({
        path: "/financeList",
        query: {
          parame: JSON.stringify(parame),
          // _this:this
        },
      });
      window.open(routeData.href, "_blank");
    },
    Savebudget() {
      //小表保存
      console.log(this.tableFundData, "2222");
      var list = [];
      this.tableFundData.forEach((item) => {
        list.push({
          Id: item.AccountId,
          TopicId: this.pid,
          BaseAccountId: item.Id,
          CentralBudget: item.CentralBudget * 10000,
          OtherBudget: item.OtherBudget * 10000,
        });
      });
      let params = {
        Accounts: list,
      };
      api.UpdatebudgetData(params).then((res) => {
        if (res.data.ExtraData == true) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.DisplayReportlist(this.pid);
        } else {
          alert(res.data.Message);
        }
      });
    },
    changeBudgetNum() {
      this.SavebudgetFinancialcalculation();
    },
    SavebudgetFinancialcalculation() {
      //计算小表值
      this.tableFundData = this.tableFundData.map((item, index, arr) => {
        var obj = {
          BudgetNum: 0,
          CentralBudget: 0,
          OtherBudget: 0,
        };
        if (index == 2) {
          for (var i2 = item.SumStart - 1; i2 < item.SumEnd; i2++) {
            obj.BudgetNum += arr[i2].BudgetNum * 1;
            obj.CentralBudget += arr[i2].CentralBudget * 1;
            obj.OtherBudget += arr[i2].OtherBudget * 1;
          }
          item.BudgetNum = obj.BudgetNum;
          item.CentralBudget = obj.CentralBudget;
          item.OtherBudget = obj.OtherBudget;
        }
        if (index == 1) {
          for (var i1 = item.SumStart - 1; i1 < item.SumEnd; i1++) {
            obj.BudgetNum += arr[i1].BudgetNum * 1;
            obj.CentralBudget += arr[i1].CentralBudget * 1;
            obj.OtherBudget += arr[i1].OtherBudget * 1;
          }
          item.BudgetNum = obj.BudgetNum;
          item.CentralBudget = obj.CentralBudget;
          item.OtherBudget = obj.OtherBudget;
        }
        if (index == 0) {
          for (var i = item.SumStart - 1; i < item.SumEnd; i++) {
            obj.BudgetNum += arr[i].BudgetNum * 1;
            obj.CentralBudget += arr[i].CentralBudget * 1;
            obj.OtherBudget += arr[i].OtherBudget * 1;
          }
          item.BudgetNum = obj.BudgetNum;
          item.CentralBudget = obj.CentralBudget;
          item.OtherBudget = obj.OtherBudget;
        }
        item.BudgetNum = item.CentralBudget * 1 + item.OtherBudget * 1;
        return item;
      });
      console.log(this.tableFundData);
    },
    clickCentralSurplus(i, row) {
      console.log(row);
      let parame = {
        topicId: this.projectId,
        Id: row.Id,
        field: "CentralSurplus",
      };
      // this.voucherListData = parame;
      // this.Voucherlistdialog = true;
      let routeData = this.$router.resolve({
        path: "/financeList",
        query: {
          parame: JSON.stringify(parame),
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    this.getTreeData();
  },
  mounted() {
    window['getData'] = () => {
      this.getTreeData()
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";

.inp {
  color: red;
}

.financialmanagementdialog {
  /deep/.el-table {
    @include add-size($font_size_16);
    color: #333333;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  background-color: #1890ff;
  border-radius: 10px;
}

.ci {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff9900;
}

a {
  color: #3388ff;
}

.span10 {
  margin-left: 10px;
}

.span11 {
  margin-left: 20px;
}

.span12 {
  margin-left: 60px;
}

.span13 {
  margin-left: 80px;
}

.span14 {
  margin-left: 80px;
}

.span15 {
  margin-left: 80px;
}

.span16 {
  margin-left: 80px;
}

.span17 {
  margin-left: 60px;
}

.span18 {
  margin-left: 60px;
}

.span19 {
  margin-left: 60px;
}

.span20 {
  margin-left: 60px;
}

.span21 {
  margin-left: 60px;
}

.span22 {
  margin-left: 60px;
}

.span23 {
  margin-left: 60px;
}

.span24 {
  margin-left: 60px;
}

.span25 {
  margin-left: 20px;
}

.btn {
  height: 70px;
  text-align: center;

  // background: red;
  /deep/.el-button {
    margin-top: 20px;
  }
}

.box {
  height: 100%;
  width: 100%;

  .box_left {
    width: 240px;
    height: 94%;
    float: left;
    margin-top: 2%;
    border-radius: 10px;
    box-sizing: border-box;
    background: white;
  }

  .box_right {
    width: 83%;
    height: 94%;
    float: left;
    margin-top: 2%;
    margin-left: 30px;
    padding: 1% 1% 0 1%;
    box-sizing: border-box;
    background: #fff;
    transition: width 1s;

    #xqq {
      height: 90%;
      overflow: auto;

      .xqq_fen {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      }

      .xqq_checkbox {
        text-align: center;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;

        /deep/.el-checkbox__label {
          font-size: 16px;
        }
      }

      .ple {
        .box_body {
          width: 100%;

          .box_div1 {
            float: left;

            .box_div1_one {
              width: 450px;
              height: 60px;
              float: left;

              .box_divone1 {
                text-align: center;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 450px;
                  height: 60px;
                  border-left: 1px solid #d4d4d4;
                  border-top: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  // border-bottom: 1px solid #d4d4d4;
                  box-sizing: border-box;
                  background: #fbfbfd;
                  font-size: 16px;
                  color: #333333;
                }
              }

              .box_divone2 {
                span:nth-child(1) {
                  display: inline-block;
                  width: 450px;
                  height: 60px;
                  border-left: 1px solid #d4d4d4;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                  background: #fbfbfd;
                }
              }

              .box_div_two {
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 450px;
                  height: 60px;
                  border-left: 1px solid #d4d4d4;
                  // border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }
              }
            }

            .box_div2 {
              width: 450px;
              height: 60px;
              float: left;
              background: #f9faff;

              .box_div2_1 {
                text-align: center;
                background: #f9faff;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 450px;
                  height: 60px;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                  background: #f9faff;
                }
              }

              .box_div2_2 {
                text-align: center;
                background: #f9faff;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 150px;
                  height: 60px;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(2) {
                  display: inline-block;
                  width: 150px;
                  height: 60px;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(3) {
                  display: inline-block;
                  width: 148px;
                  height: 60px;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }
              }

              .box_div2_3 {
                background: #f9faff;
                overflow: hidden;

                .el-input-number {
                  width: 120px;
                  margin-left: 15px;
                  margin-top: 10px;
                  //  color: red;
                  // /deep/.el-input__inner{
                  //   color: red;
                  // }
                }

                input {
                  text-align: center;
                  color: red;
                  width: 120px;
                  margin-left: 15px;
                  margin-top: 10px;
                  height: 34px;
                  font-size: 16px;
                  outline: none;
                }

                span:nth-child(1) {
                  display: inline-block;
                  width: 150px;
                  height: 60px;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(2) {
                  display: inline-block;
                  width: 150px;
                  height: 60px;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(3) {
                  display: inline-block;
                  width: 148px;
                  height: 60px;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }
              }
            }

            .box_div3 {
              width: 150px;
              height: 60px;
              float: left;

              .box_div3_1 {
                text-align: center;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 150px;
                  height: 60px;
                  border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                  color: #3388ff;
                }
              }

              .box_div3_2 {
                text-align: center;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 75px;
                  height: 60px;
                  // border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(2) {
                  display: inline-block;
                  width: 75px;
                  height: 60px;
                  // border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }
              }

              .box_div3_3 {
                text-align: center;
                line-height: 60px;

                span:nth-child(1) {
                  display: inline-block;
                  width: 75px;
                  height: 60px;
                  // border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }

                span:nth-child(2) {
                  display: inline-block;
                  width: 75px;
                  height: 60px;
                  // border-top: 1px solid #d4d4d4;
                  border-bottom: 1px solid #d4d4d4;
                  border-right: 1px solid #d4d4d4;
                  box-sizing: border-box;
                }
              }
            }
          }

          .div4 {
            // background: red;
            // display: inline-block;
            float: left;

            // display: inline-block;
            // width: 150px;
            // height: 400px;
            // background: coral;
            .div4_1 {
              text-align: center;
              line-height: 60px;

              span:nth-child(1) {
                display: inline-block;
                width: 150px;
                height: 60px;
                border-top: 1px solid #d4d4d4;
                border-bottom: 1px solid #d4d4d4;
                border-right: 1px solid #d4d4d4;
                box-sizing: border-box;
                color: #3388ff;
              }
            }

            .div4_2 {
              text-align: center;
              line-height: 60px;

              span:nth-child(1) {
                display: inline-block;
                width: 75px;
                height: 60px;
                // border-top: 1px solid #d4d4d4;
                border-bottom: 1px solid #d4d4d4;
                border-right: 1px solid #d4d4d4;
                box-sizing: border-box;
              }

              span:nth-child(2) {
                display: inline-block;
                width: 75px;
                height: 60px;
                // border-top: 1px solid #d4d4d4;
                border-bottom: 1px solid #d4d4d4;
                border-right: 1px solid #d4d4d4;
                box-sizing: border-box;
              }
            }

            .div4_3 {
              text-align: center;
              line-height: 60px;

              span:nth-child(1) {
                display: inline-block;
                width: 75px;
                height: 60px;
                // border-top: 1px solid #d4d4d4;
                border-bottom: 1px solid #d4d4d4;
                border-right: 1px solid #d4d4d4;
                box-sizing: border-box;
              }

              span:nth-child(2) {
                display: inline-block;
                width: 75px;
                height: 60px;
                //  border-top: 1px solid #d4d4d4;
                border-bottom: 1px solid #d4d4d4;
                border-right: 1px solid #d4d4d4;
                box-sizing: border-box;
              }
            }
          }

          .fnfn {
            background: #fffff9fa;
          }

          .nfnf {
            background: #fff2fffe;
          }
        }
      }
    }
  }

  .box_right1 {
    width: 85%;
  }
}

.financialmanagementdialog {
  /deep/.el-dialog {
    width: 1700px;
    border-radius: 10px;

    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;

      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
        }

        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }

    .body {
      height: 700px;
      background: blue;
      overflow: auto;

      .el-input-number {
        width: 120px;
      }
    }

    .btn {
      text-align: center;
      overflow: hidden;

      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
        margin-right: 20px;
        margin-top: 20px;
      }

      button:nth-child(2) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
        margin-top: 20px;
      }
    }
  }
}

.Voucherlistdialog {
  /deep/.el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;

      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
        }

        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }

    .body {
      padding-top: 2%;
      padding-left: 8%;
      //background: blue;
      box-sizing: border-box;
    }

    .btn {
      text-align: center;

      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
        margin-right: 20px;
      }

      button:nth-child(2) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
    }
  }
}

.footer_btn {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 3px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
}

.po /deep/.el-input__inner {
  color: red;
}

.Voucherlistdialog1 {
  /deep/.el-dialog {
    width: 450px;
    height: 260px;
    border-radius: 10px;

    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;

      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }

        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }

    .body {
      height: 70px;
      text-align: center;

      span:nth-child(1) {
        display: inline-block;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        background: #ffba00;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        vertical-align: middle;
      }

      span:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
        color: #666666;
        @include add-size1($font_size_18);
      }
    }

    .btn {
      text-align: center;

      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }

      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}

/deep/.el-message-box__content {
  font-size: 16px;
}

/deep/.el-button--mini,
.el-button--small {
  font-size: 16px;
}
</style>
